import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TableTemplate from "components/TableTemplate"

import toast from "react-hot-toast";
import moment from "moment"

import * as setup from "models/setup";

import AddEditCategory from "./AddEditCategory"
import DeleteCategory from "./DeleteCategory"
import { Card, InputGroup, Form } from "react-bootstrap";

const Categories = props => {
  const [categories, setCatories] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)
  const [searchText, setSearchText] = useState("")

  //modal
  const [showAddEditCategory, setShowAddEditCategory] = useState(false)
  const [showdeleteCategory, setShowDeleteCategory] = useState(false)

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            {/* <Link
              className="btn btn-sm text-primary text-nowrap "
              to={`/user-profile/${row?._id}`}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                View
              </p>
            </Link> */}
            <button
              className="btn btn-sm text-primary text-nowrap "
              onClick={() => {
                setShowAddEditCategory(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteCategory(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },

    { name: "Name", selector: row => row?.categoryName, sortable: true },
    { name: "Created", selector: row => moment(row?.created).format('LL'), sortable: true }


  ]

  const onFilter = (e) => {
    const searchValue = e.target.value
    setSearchText(searchValue)
  }

  const subHeaderNav = (
    <div className="d-flex justify-content-between w-100">
      <div className="justifycontent-between   align-items-center search-bar-container   rounded-0   ">

        <InputGroup className="rounded rounded-0 bg-white search-bar " >
          <span className="fw-bold input-group-text bg-primary  rounded rounded-0 text-white">
            {/* <i className="fa fa-search" /> */}
            Search
          </span>
          <Form.Control
            value={searchText}
            onChange={onFilter}
            placeholder="Type to search..."
            className="border-1 shadow-none rounded rounded-0 "
            size="md"
          />
          <button
            type="button"
            onClick={() => setSearchText("")}
            className={`btn  rounded rounded-0 text-primary fw-bold py-1 border  border-1 ${searchText.length > 0 ? "" : "d-none"
              }`}
          >
            X
          </button>


        </InputGroup>

      </div>
      <div className="d-none  d-md-flex justify-content-end">
        <button className="btn btn-primary" onClick={() => { setShowAddEditCategory(true) }}>
          Add Thematic Area
        </button>
      </div>
    </div>

  )



  const getAllCategories = (params) => {
    setIsLoaded(false)
    setup.getAllCategories(params)
      .then(resp => {
        const data = resp?.categories
        setCatories(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })

  }

  useEffect(() => {
    getAllCategories()
  }, [])
  useEffect(() => {
    getAllCategories(`search=${searchText}`)
  }, [searchText])
  return (
    <Card >
      <Card.Header>
        <Card.Title className="text-primary"> Thematic Areas</Card.Title>
      </Card.Header>
      <div className="d-flex  d-md-none p-2 px-3 justify-content-end">
        <button className="btn btn-primary" onClick={() => { setShowAddEditCategory(true) }}>
          Add Thematic Area
        </button>
      </div>
      <TableTemplate
        dataLoaded={isLoaded}
        rows={categories}
        columns={columns}
        subHeaderNav={subHeaderNav}
      />
      <AddEditCategory
        showModal={showAddEditCategory}
        setShowModal={setShowAddEditCategory}
        toEdit={toEdit}
        setToEdit={setToEdit}
        selected={selected}
        setSelected={setSelected}
        fetchData={getAllCategories} />

      <DeleteCategory
        showModal={showdeleteCategory}
        setShowModal={setShowDeleteCategory}
        selected={selected}
        setSelected={setSelected}
        fetchData={getAllCategories}
      />

    </Card>
  )
}

Categories.propTypes = {}

export default Categories