import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from "react-bootstrap"
import moment from "moment"

const ViewLog = props => {
  const [isLoading, setIsLoading] = useState(false)

  const closeModal = () => {
    props.setShowModal(false)
    setIsLoading(false)
    props.setSelected(null)
  }
  return (
    <React.Fragment>
      <Modal show={props.showModal}
        onHide={() => closeModal()}
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">Log Id {props.selected?._id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-1">
            <h6>

            </h6>
            User {props?.selected?.userInfo?.userEmail} {props.selected?.action} {props.selected?.actionItem}
          </div>
          <div>
            <h6>Details</h6>
            <ul>
              <li>Ip adress:{props.selected?.details?.ip}</li>
              <li>
                Accessed from:{props.selected?.details?.userAgent}
              </li>
              <li>
                Timestamp: {moment(props.selected?.created).format('MMMM Do YYYY, h:mm:ss a')}
              </li>
            </ul>




          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

ViewLog.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
}

export default ViewLog