import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

//Categories


//get functions
export const getAllAnalytics = async (params) => {
  return await axios
    .get(`${getApi.ANALYTICS.GET_ANALYTICS}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

