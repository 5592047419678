import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"
import { Col, Dropdown, Row } from "react-bootstrap"
//import getChartColorsArray from "components/Common/ChartsDynamicColor"

import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import { scaleQuantize } from "d3-scale"
import { csv } from "d3-fetch"
import feature from "./kenyan-counties.geojson"

const Overview = ({ dataColors, departments }) => {
  //const stackedColumnChartColors = getChartColorsArray(dataColors)
  const [activeDepartment, setActiveDepartment] = useState("All Departments")
  const [graduates, setGraduates] = useState([])
  const [users, setUsers] = useState([])

  const [loadUsers, setLoadUsers] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [male, setMale] = useState([])
  const [female, setFemale] = useState([])
  const [graphdataMale, setGraphDataMale] = useState({})
  const [graphdataFemale, setGraphDataFemale] = useState({})
  const [mapFemale, setMapFemale] = useState([])
  const [mapMale, setMapMale] = useState([])
  const [mapGraduate, setMapGraduate] = useState([])

  const [hoveredRegion, setHoveredRegion] = useState(null)

  const countyColors = {
    Kwale: "#FFB100",
    Kilifi: "#FFB100",
    Kitui: "#FFB100",
    Turkana: "#FFB100"

  };

  const handleMouseEnter = region => {
    setHoveredRegion(region)
    const filter = graduates.filter(item => item.county === region)
    setMapGraduate(filter)
    setMapMale(filter.filter(item => item.gender === "Male"))
    setMapFemale(filter.filter(item => item.gender === "Female"))
  }

  const handleMouseLeave = () => {
    setHoveredRegion(null)
    setMapGraduate([])
    setMapMale([])
    setMapFemale([])
  }

  const series = [
    {
      name: "Pdf",
      data: [20, 50]
    },
    {
      name: "Audio",
      data: [40, 20],
    },
    {
      name: "Video",
      data: [40, 20],
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "30%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: ["Male", "Female"],
      labels: {
        show: true,
      },
    },
    // colors: stackedColumnChartColors,
    legend: {
      position: "right",
      //offsetY: "0%",
    },

    responsive: [
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            bar: {
              horizontal: !1,
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    fill: {
      opacity: 1,
    },
  }
  const [data, setData] = useState([])

  useEffect(() => {
    // https://www.bls.gov/lau/
    csv("/unemployment-by-county-2017.csv").then(counties => {
      setData(counties)
    })
  }, [])

  // Grouping function
  const groupBy = (array, ...keys) => {
    return array.reduce((acc, obj) => {
      const property = keys.reduce((o, key) => {
        const value = obj[key]
        if (!o[value]) o[value] = []
        return o[value]
      }, acc)
      property.push(obj)
      return acc
    }, {})
  }

  const GetAllUsers = () => {
    const profile = JSON.parse(localStorage.getItem("sysAdmin"))
    const token = profile?.token
    setLoadUsers(true)

    api
      .get("api/AccountManagement/GetAllUsers?&$Filter=Role eq 'Graduate'", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const data = response.data.$values

        setGraduates(data)

        setUsers(data)

        /**
         * group by gender
         * then by year
         * then by program
         */

        setLoadUsers(false)
      })
      .catch(error => {
        setLoadUsers(false)
        if (error.toJSON().message === "Network Error") {
          toast.error("Network Error. Check Internet Connection")
        } else {
          console.log(error)
        }
      })
  }

  useEffect(() => {
    setMale(graduates.filter(item => item.gender === "Male"))
    setFemale(graduates.filter(item => item.gender === "Female"))
    const groupedData = groupBy(
      graduates.filter(item => item.gender === "Male"),
      "graduationYear"
    )

    setGraphDataMale(groupedData)
    const groupfemale = groupBy(
      graduates.filter(item => item.gender === "Female"),
      "graduationYear"
    )
    setGraphDataFemale(groupfemale)
  }, [graduates])

  // GetAllusers
  // useEffect(() => {
  //   GetAllUsers()
  // }, [])
  useEffect(() => {
    const dept =
      activeDepartment === "All Departments"
        ? setGraduates(users)
        : setGraduates(
          users.filter(item => item?.department === activeDepartment)
        )
  }, [activeDepartment])
  return (
    <div className="bg-white my-3 rounded">
      <h5 className="p-3">Overview</h5>
      <Row className="  d-flex  flex-md-row">
        <div className=" col-md-7 ">
          <p className="mx-3">
            Downloads Distribution by Gender
          </p>


          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height="400"

          //className="apex-charts"
          />

          <div className="d-flex px-3 mt-5 pb-2"></div>
        </div>
        <Col className=" bg-white ">
          <p className="mx-3">
            Number of downloads per county.
          </p>
          {/* <ComposableMap
            //</>projection="geoAlbersUsa"
            projection={"geoAzimuthalEqualArea"}
            projectionConfig={{
              rotate: [0, 0, 0],
              center: [38, 0],
              scale: 2500,
            }}
            height={500}
            width={400}
            color="green"
          >
            <div className="bg-success">fffff</div>
            <Geographies
              geography={feature}
              fill="#FF55"
              //stroke="#fff"
              style={{
                default: {
                  fill: "#fff",
                },
                hover: {
                  fill: "#F53",
                },
                pressed: {
                  fill: "#E42",
                },
              }}
            >
              {({ geographies }) =>
                geographies.map(geo => {
                  const cur = data.find(s => s.id === geo.id)
                  return (
                    <Geography
                      _hover={{
                        fill: "#fff",
                      }}
                      key={geo.rsmKey}
                      geography={geo}
                      //  fill={"#E42"}
                      //  fill={colorScale(cur ? cur.unemployment_rate : "#EEE")}
                      style={{
                        default: {
                          fill: "#0098DB",
                        },
                        pressed: {
                          fill: "#E42",
                        },
                      }}
                      height={0}
                    />
                  )
                })
              }
            </Geographies>
          </ComposableMap> */}
          <ComposableMap
            projection={"geoAzimuthalEqualArea"}
            projectionConfig={{
              rotate: [0, 0, 0],
              center: [38, 0],
              scale: 2500,
            }}
            height={500}
            width={400}
            color="green"
          >
            <Geographies geography={feature}>
              {({ geographies }) =>
                geographies.map(geo => {
                  const countyName = geo.properties?.COUNTY;
                  const fillColor = countyColors[countyName] || "#082F1A"; // Default fill color if county not in the list

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() => handleMouseEnter(countyName)}
                      onMouseLeave={handleMouseLeave}
                      style={{
                        default: {
                          fill: fillColor,
                          stroke: "#fff",
                          strokeWidth: 0.75,
                          outline: "none",
                        },
                        hover: {
                          fill: "#fff",
                          stroke: "#607D8B",
                          strokeWidth: 0.75,
                          outline: "none",
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
          {hoveredRegion && (
            <div
              style={{
                position: "absolute",
                top: "20px",
                left: "200px",
                backgroundColor: "#FFB100",
                padding: "10px",
                borderRadius: "5px",
              }}

              className="text-white"
            >
              {/* Render the content of the popup */}
              <p className="m-0">{hoveredRegion} County</p>
              <p className="m-0"> Total downloads:{mapGraduate.length}</p>
              <p className="m-0"> Female downloads:{mapFemale.length}</p>
              <p className="m-0">Male downloads:{mapMale.length}</p>
            </div>
          )}
        </Col>

      </Row>
    </div>
  )
}

Overview.propTypes = { departments: PropTypes.array }

export default Overview
