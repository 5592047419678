import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

export const getAllLogs = async (params) => {
  return await axios
    .get(`${getApi.LOGS.GET_ALL_LOGS}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError(err, err));
}

export const getLogById = async (logId) => {
  return await axios
    .get(`${getApi.LOGS.GET_LOG_BY_ID}/${logId}`)
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError(err, err));
}