import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Col, Row } from "react-bootstrap"

import * as auth from "models/auth"
import * as file from "models/files";

import toast from "react-hot-toast";

const ScoreCard = props => {
  const [users, setUsers] = useState([])
  const [files, setFiles] = useState([])
  //processing variables
  const [isLoaded, setIsLoaded] = useState(false)

  const cardItems = [
    {
      title: "Total Files", value: files.length, icon: 'fas fa-file',
      moreInfo: [
        {
          value: 50,
          title: 'Pdf',
          icon: 'fas fa-list-check',
          color: 'primary',
        },
        {
          value: 30,
          title: 'Audio',
          icon: 'fas fa-list-check',
          color: 'primary',
        },
        {
          value: 20,
          title: 'Video ',
          icon: 'fas fa-search',
          color: 'primary',
        },
      ]


    },
    { title: "Downloads", value: "45", icon: 'fas fa-download', },
    { title: "Users", value: users.length, icon: 'fas fa-users', },

  ]

  const getAllUsers = (params) => {
    setIsLoaded(false)
    auth.getAllUsers(params)
      .then(resp => {
        const data = resp.users
        setUsers(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }
  const getAllFiles = (params) => {

    file.getAllFiles(params)
      .then(resp => {
        const data = resp.files
        setFiles(data)
        setIsLoaded(true)
      }).catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }
  useEffect(() => {
    getAllUsers()
    getAllFiles()
  }, [])
  return (
    <div className="">
      <Row>
        {cardItems.map((data, key) =>
          <Col md='4' className="d-flex " key={key}>
            <Card className="flex-fill">
              <Card.Body className="d-flex flex-column justify-content-center">
                <h6 className="mb-2 fw-bold">
                  <i className={`${data?.icon} me-1 bg-primary p-1 text-white`} />
                  {data?.title}
                </h6>

                <h5 className="text-dark">
                  {data?.value}
                </h5>
                {/* <div className="mt-2 d-flex justify-content-start text-primary">
                  {data?.moreInfo?.map((data2, key2) =>
                    <div className="flex-grow-1" key={key2}>
                      <h6 className={`mb-1 text-${data2.color}`}>
                        <i className={`${data2.icon} me-1`} />
                        {data2.title}
                      </h6>

                      <p className={`mb-0 fw-bold text-${data2.color}`}>
                        {data2.value}
                      </p>
                    </div>
                  )}
                </div> */}
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  )
}

ScoreCard.propTypes = {}

export default ScoreCard