import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, InputGroup, Alert, Row, Col, Spinner, Container } from "react-bootstrap"
import { withRouter, Link } from "react-router-dom"
// Toast Alert
import toast from "react-hot-toast"

//
import logo from "./../../assets/images/Kara-Logo.png"

import * as auth from "models/auth"
import AuthBody from "./AuthBody"

const ForgetPassword = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const [showForgot, setShowForgot] = useState(true)
  const [showNewPass, setShowNewPass] = useState(false)

  const forgetPassword = (event) => {
    setIsLoading(true)
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    const payload = { userEmail: form.userEmail.value, resetUrl: "admin.projects.kara.or.ke/set-password" }
    auth.forgotPassword(payload)
      .then(resp => {
        setShowForgot(false)
        setIsLoading(false)
        toast.success(resp?.message)
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }

  const setNewPass = (event) => {
    setIsLoading(true)
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()

    const payload = {
      token: form.token.value,
      password: form.password.value,
      confirmPassword: form.confirmPassword.value,
    }

    auth.setPassword(payload)
      .then(resp => {
        toast.success(resp?.data)
        setIsLoading(false)
        toast.success(resp?.message)
        props.history.push("/login")

      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }
  const mainForm = (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col sm={10} md={8}>
            <Card className="overflow-hidden">
              <div className="py-2 px-4">
                <span className="logo-lg">
                  <img
                    src={logo}
                    alt=""
                    height="60"
                    className="image-fluid"
                  />
                </span>
              </div>
              <Card.Body className="pt-0">
                <div className="p-2">
                  {/* {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null} */}
                  {/* <h2 className="text-primary mt-3">Homes of Choice</h2> */}
                  {showForgot ? (
                    <>
                      <h4 className="text-primary pb-3">Forgot Password</h4>

                      <Form
                        className="form-horizontal"
                        onSubmit={forgetPassword}
                      >
                        <Form.Group className="mb-3 text-primary" controlId="userEmail">
                          <Form.Label>User Email</Form.Label>
                          <InputGroup className="form-control-lgm">
                            <Form.Control
                              type="email"
                              placeholder="e.g. johnsmith@email.com"
                              required
                            />
                          </InputGroup>
                          <Form.Control.Feedback type="invalid">
                            Invalid Email
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary btn-block btn-lg rounded-0"
                              type="submit"
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />{" "}
                                  Processing...
                                </>
                              ) : (
                                "Reset"
                              )}
                            </button>
                          </Col>
                        </Row>
                        <div className="mt-2 text-center">
                          <p>

                            <a onClick={() => { setShowForgot(false) }} className="font-weight-medium text-primary">
                              Already have a token?
                            </a>{" "}
                          </p>
                        </div>
                      </Form>
                    </>
                  ) : <>
                    <h4 className="text-primary pb-3">Set New Password</h4>

                    <Form onSubmit={setNewPass}>
                      <Form.Group className="mb-3" controlId="token">
                        <Form.Label>Token</Form.Label>
                        <InputGroup className="form-control-lgm">
                          <Form.Control
                            type="text"
                            placeholder="Enter token here"
                            required
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="password">
                        <Form.Label>Password</Form.Label>{" "}
                        <InputGroup className="form-control-lgm">
                          <Form.Control
                            type="password"
                            placeholder="*****"
                            required
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="confirmPassword"
                      >
                        <Form.Label>Confirm Password</Form.Label>{" "}
                        <InputGroup className="form-control-lgm">
                          <Form.Control
                            type="password"
                            placeholder="*****"
                            required
                          />
                        </InputGroup>
                      </Form.Group>
                      <Col className="text-end">
                        <button
                          className="btn btn-primary btn-block btn-lg rounded-0"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Processing...
                            </>
                          ) : (
                            "Save"
                          )}
                        </button>
                      </Col>
                      <div className="mt-2 text-center">
                        <p>
                          Go back to{" "}
                          <a onClick={() => { setShowForgot(true) }} className="font-weight-medium text-primary">
                            Forgot Password
                          </a>{" "}
                        </p>
                      </div>
                    </Form>
                  </>}

                </div>
              </Card.Body>
            </Card>
            <div className="mt-5 text-center">
              <p>
                Go back to{" "}
                <Link to="login" className="font-weight-medium text-primary">
                  Login
                </Link>{" "}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  )
  return (
    <AuthBody
      form={mainForm}
      meta={"Forgot Password | KARA"} />
  )
}

ForgetPassword.propTypes = {}

export default ForgetPassword