import React from 'react'
import PropTypes from 'prop-types'
import { Card } from "react-bootstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import ScoreCard from "pages/Dashboard/ScoreCard"

const Analytics = props => {
  return (
    <div className="page-content">
      {/* Render Breadcrumb */}
      <Breadcrumbs
        title={("Analytics ")}
        breadcrumbItem={("Analytics ")}
      />
      <Card>
        <Card.Body>
          <ScoreCard />
        </Card.Body>
      </Card>
    </div>
  )
}

Analytics.propTypes = {}

export default Analytics