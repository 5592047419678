import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";

import toast from "react-hot-toast";
import moment from "moment"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import * as file from "models/files";

import TableTemplate from "components/TableTemplate"
import AddEditFile from "./AddEditFile"
import DeleteFile from "./DeleteFile"

const ContentManagement = props => {
  const [files, setFiles] = useState([])

  //processing variables
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)

  //modal
  const [showAddEditFile, setShowAddEditFile] = useState(false)
  const [showdeleteFile, setShowDeleteFile] = useState(false)

  const [activeTab, setActiveTab] = useState({ name: "Pdf", value: "pdf" })

  const tabs = [
    { name: "Pdf", value: "pdf" },
    { name: "Audio", value: "audio" },
    { name: "Video", value: "video" },

  ]
  function formatBytes(bytes) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const value = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

    return `${value} ${sizes[i]}`;
  }

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <button
              className="btn btn-sm text-primary text-nowrap "
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                View
              </p>
            </button>
            <button
              className="btn btn-sm text-secondary text-nowrap "
              onClick={() => {
                setShowAddEditFile(true)
                setSelected(row),

                  setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteFile(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },

    { name: "Title", selector: row => row?.title },
    { name: "Category", selector: row => row?.category },
    { name: "Size", selector: row => formatBytes(row?.size) },
    { name: "Created", selector: row => moment(row?.created).format('LL'), }


  ]

  const subHeaderNav = (
    <div className="d-flex flex-column flex-md-row justify-content-between   w-100">
      <div className="d-flex justify-content-between " style={{ maxWidth: 200 }} >
        {tabs.map(((item, index) => (
          <div
            key={index}
            className={`d-flex align-items-center  border-3 btn btn-sm border-0  py-1 me-2 me-md-5 border-bottom 
                  
                  bg-${activeTab?.name === item?.name ? "primary" : "white"} `}
            href={`#${item.name}`}
            onClick={() => setActiveTab({ name: item.name, value: item?.value })}
          >
            <h6 className={`me-1 me-md-3 my-1 text-${activeTab?.name === item.name ? "white" : "primary"}`} >
              {item.name}
            </h6>
          </div>

        )))}
        {/* <button className="btn btn-primary"
        onClick={() => setShowAddEditUser(true)}>
        Add Users
      </button> */}
      </div>
      <div className=" d-none d-md-flex justify-content-end">
        <button className="btn btn-primary" onClick={() => { setShowAddEditFile(true) }}>
          Add File
        </button>
      </div>
    </div>
  )

  const getAllFiles = (params) => {

    file.getAllFiles(params)
      .then(resp => {
        const data = resp.files
        setFiles(data)
        setIsLoaded(true)
      }).catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    setIsLoaded(false)
    const query = `fileType=${activeTab?.value}`
    getAllFiles(query)
  }, [activeTab])

  useEffect(() => {
    getAllFiles(`fileType=${activeTab?.value}`)
  }, [])
  return (
    <div className="page-content ">
      {/* Render Breadcrumb */}
      <Breadcrumbs
        title={("Content Management")}
        breadcrumbItem={("Content Management")}
      />
      <div className=" d-flex d-md-none pb-2 justify-content-end">
        <button className="btn btn-primary" onClick={() => { setShowAddEditFile(true) }}>
          Add File
        </button>
      </div>
      <Card>
        <Card.Body>
          <TableTemplate
            dataLoaded={isLoaded}
            rows={files}
            columns={columns}
            subHeaderNav={subHeaderNav} />
          <AddEditFile
            showModal={showAddEditFile}
            setShowModal={setShowAddEditFile}
            toEdit={toEdit}
            setToEdit={setToEdit}
            selected={selected}
            setSelected={setSelected}
            fetchData={() => getAllFiles(`fileType=${activeTab?.value}`)} />
          <DeleteFile
            showModal={showdeleteFile}
            setShowModal={setShowDeleteFile}
            selected={selected}
            setSelected={setSelected}
            fetchData={() => getAllFiles(`fileType=${activeTab?.value}`)} />
        </Card.Body>
      </Card>
    </div>
  )
}

ContentManagement.propTypes = {
  //t: PropTypes.any,
}

export default ContentManagement