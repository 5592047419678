import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";

import toast from "react-hot-toast";
import moment from "moment"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import * as auth from "models/auth";

import AddEditUser from "./AddEditUser"
import TableTemplate from "components/TableTemplate"
import DeleteUser from "./DeleteUser";


const UserManagement = props => {
  const [users, setUsers] = useState([])

  //processing variables
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)

  //modal
  const [showAddEditUser, setShowAddEditUser] = useState(false)
  const [showdeleteUser, setShowDeleteUser] = useState(false)

  const [activeTab, setActiveTab] = useState({ name: "Admins", value: "admin" })

  const tabs = [
    { name: "Admins", value: "admin" },
    { name: "Users", value: "user " },

  ]

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            {/* <Link
              className="btn btn-sm text-primary text-nowrap "
              to={`/user-profile/${row?._id}`}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                View
              </p>
            </Link> */}
            <button
              className="btn btn-sm text-secondary text-nowrap "
              onClick={() => {
                setShowAddEditUser(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteUser(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },

    { name: "Name", selector: row => row?.fullName },
    { name: "Email", selector: row => row?.userEmail },
    { name: "Phone Number", selector: row => row?.phoneNumber },
    { name: "Location", selector: row => row?.location },
    { name: "Created", selector: row => moment(row?.created).format('LL'), }


  ]

  const subHeaderNav = (
    <div className="d-flex flex-column flex-md-row justify-content-between  w-100">

      <div className="d-flex justify-content-between" style={{ maxWidth: 200 }} >
        {tabs.map(((item, index) => (
          <div
            key={index}
            className={`d-flex align-items-center justify-content-center border-3 btn btn-sm border-0 me-5 border-bottom 
                  
                  bg-${activeTab?.name === item?.name ? "primary" : "white"} `}
            href={`#${item.name}`}
            onClick={() => setActiveTab({ name: item.name, value: item?.value })}
          >
            <h6 className={`me-3 my-1 fontSize-5 text-${activeTab?.name === item.name ? "white" : "primary"}`} >
              {item.name}
            </h6>
          </div>

        )))}

      </div>
      <div className=" d-none d-md-flex justify-content-end">
        <button className="btn btn-primary" onClick={() => { setShowAddEditUser(true) }}>
          Add User
        </button>
      </div>
    </div>
  )

  const getAllUsers = (params) => {
    setIsLoaded(false)
    auth.getAllUsers(params)
      .then(resp => {
        const data = resp.users
        setUsers(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  const getUserByParams = () => {

  }

  useEffect(() => {
    getAllUsers(`userType=${activeTab?.value}`)
  }, [activeTab])
  useEffect(() => {
    getAllUsers(`userType=${activeTab?.value}`)
  }, [])


  return (
    <div className="page-content ">
      {/* Render Breadcrumb */}
      <Breadcrumbs
        title={("Content Management")}
        breadcrumbItem={("Content Management")}
      />
      <div className=" d-flex d-md-none pb-2 justify-content-end">
        <button className="btn btn-primary" onClick={() => { setShowAddEditUser(true) }}>
          Add User
        </button>
      </div>
      <Card>
        <Card.Body>
          <TableTemplate
            dataLoaded={isLoaded}
            rows={users}
            columns={columns}
            subHeaderNav={subHeaderNav}
          />

          {/* Modals */}
          <AddEditUser
            showModal={showAddEditUser}
            setShowModal={setShowAddEditUser}
            toEdit={toEdit}
            setToEdit={setToEdit}
            selected={selected}
            setSelected={setSelected}
            fetchData={() => getAllUsers(`userType=${activeTab?.value}`)}
          />

          {/* delete Modal */}
          <DeleteUser
            showModal={showdeleteUser}
            setShowModal={setShowDeleteUser}
            selected={selected}
            setSelected={setSelected}
            fetchData={() => getAllUsers(`userType=${activeTab?.value}`)}
          />
        </Card.Body>
      </Card>
    </div>
  )
}

UserManagement.propTypes = {}

export default UserManagement