import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

//Post Funtions
export const addFiles = async (payload, token) => {
  return await axios
    .post(getApi.FILES.ADD_FILE, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const uploadFile = async (formData, fileId, token) => {
  return await axios
    .post(`${getApi.FILES.UPLOAD_FILE}/${fileId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },

      onUploadProgress: (progressEvent) => {
        const percentComplete = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(`Upload progress: ${percentComplete}%`);
        // You can update your progress bar or UI here
      },

    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//get Funtions
export const getFileById = async (id) => {
  return await axios
    .get(getApi.FILES.GET_FILE_BY_ID(id))
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getAllFiles = async (params) => {
  return await axios
    .get(`${getApi.FILES.GET_ALL_FILES}?${params === undefined ? "" : params}`,)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const updateFile = async (payload, token, fileId) => {
  return await axios
    .put(`${getApi.FILES.UPDATE_FILE}/${fileId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//Delete Funtions

export const deleteFile = async (fileId, token) => {
  return await axios
    .delete(`${getApi.FILES.DELETE_FILE}/${fileId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err.message))
}
