import React from 'react'
import PropTypes from 'prop-types'
import { Card } from "react-bootstrap"
import Categories from "./Categories/Categories"
import Counties from "./Counties/Counties"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";


const Setup = props => {
  return (
    <div className="page-content">

      {/* Render Breadcrumb */}
      <Breadcrumbs
        title={("SETUP")}
        breadcrumbItem={("SETUP")}
      />

      <Categories />
      <Counties />


    </div>
  )
}

Setup.propTypes = {}

export default Setup