import PropTypes from 'prop-types';
import React, { useState } from "react";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Import menuDropdown
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu";


// import images

import logo from "assets/images/Kara-Logo.png";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store


const Header = props => {
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className="">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="d-block d-lg-none">
              <button
                type="button"
                onClick={() => {
                  tToggle();
                }}
                className="btn btn-sm px-3 font-size-16 header-item "
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars text-primary" />
              </button>
            </div>

            <div className="navbar-brand-boxs d-md-none   ps-0 ">
              <Link to="/" className="logo logo-dark ">
                <span className="logo-sm">
                  <img src={logo} alt="" height="42" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light ">
                <span className="logo-sm">
                  <img src={logo} alt="" height="40" />
                </span>
              </Link>
            </div>

            <div className=" d-none d-flex d-md-block  justify-content-start mx-5 ">
              <Link to="/" className=" ">
                <span className="logo-md">
                  <img src={logo} alt="" height="55" />
                </span>
              </Link>
            </div>





          </div>
          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1 ">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon  "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen text-white" />
              </button>
            </div>

            {/* <NotificationDropdown /> */}
            <ProfileMenu />


          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
})(withTranslation()(Header));
