import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col } from "react-bootstrap"
import toast from "react-hot-toast";

import * as auth from "models/auth";

import avatar from "assets/images/users/person-thumbnail.png"

const ViewUser = props => {
  const [user, setUser] = useState({})


  const [isLoading, setIsLoading] = useState(false)


  const {
    match: { params },
  } = props

  const getUserDetails = () => {
    setIsLoading(true)
    auth.getUserDetails(params?.id)
      .then(resp => {
        setUser(resp?.profile)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.message)
      })
  }



  useEffect(() => {
    getUserDetails()
  }, [])


  return (
    <div className="page-content">
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft m-0 text-primary py-4 px-3">
          <h4 className="text-primary">User Profile</h4>
          <p>Current Information{ }</p>
        </div>

        <Card.Body className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <span className="avatar-title rounded-circle bg-light" style={{ marginTop: '-2rem' }}>
                  <img
                    src={avatar}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </span>
              </div>
              <p className="text-muted mb-0"> Name</p>
              <h5 className="font-size-15 text-truncate">{user?.fullName}</h5>

            </Col>

            <Col sm="8" className="pt-4">
              <Row>
                <Col xs="4">
                  <p className="text-muted mb-0">Email Address</p>
                  <h5 className="font-size-15">{user?.userEmail}</h5>
                  {/* <p className="text-muted mb-0">Business Name</p>
                    <h5 className="font-size-15">{ }</h5>
                    <p className="text-muted mb-0">Business Phone</p>
                    <h5 className="font-size-15">{ }</h5> */}
                </Col>
                <Col xs="4">
                  <p className="text-muted mb-0">Phone Number</p>
                  {/* <p className="text-muted mb-0 text-truncate">{underwriters[params?.id - 1]?.phoneNumber}</p> */}
                  <h5 className="font-size-15">{user?.phoneNumber}</h5>
                  {/* <p className="text-muted mb-0">Business Type</p> */}
                  <h5 className="font-size-15 capitalize">{ }</h5>
                  <p className="text-muted mb-0"> Type</p>
                  <h5 className="font-size-15 capitalize">{user?.userType
                  }</h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

    </div>
  )
}

ViewUser.propTypes = {}

export default ViewUser