import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TableTemplate from "components/TableTemplate"

import toast from "react-hot-toast";
import moment from "moment"

import * as setup from "models/setup";


import { Card, InputGroup, Form } from "react-bootstrap";
import AddEditCounties from "./AddEditCounties";
import DeleteCounties from "./DeleteCounties";

const Counties = props => {
  const [counties, setCounties] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)
  const [searchText, setSearchText] = useState("")

  //modal
  const [showAddEditCounty, setShowAddEditCounty] = useState(false)
  const [showdeleteCounty, setShowDeleteCounty] = useState(false)

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            {/* <Link
              className="btn btn-sm text-primary text-nowrap "
              to={`/user-profile/${row?._id}`}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                View
              </p>
            </Link> */}
            <button
              className="btn btn-sm text-primary text-nowrap "
              onClick={() => {
                setShowAddEditCounty(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteCounty(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },

    { name: "Name", selector: row => row?.countyName, sortable: true },
    { name: "Number", selector: row => row?.countyNumber, sortable: true },
    { name: "Created", selector: row => moment(row?.created).format('LL'), sortable: true }


  ]


  const onFilter = (e) => {
    const searchValue = e.target.value
    setSearchText(searchValue)
  }




  const subHeaderNav = (
    <div className="d-flex justify-content-between w-100">
      <div className="justifycontent-between   align-items-center search-bar-container   rounded-0   ">

        <InputGroup className="rounded rounded-0  search-bar " >
          <span className="fw-bold input-group-text bg-primary  rounded rounded-0 text-white">
            <i className="fa fa-search" />

          </span>
          <Form.Control
            value={searchText}
            onChange={onFilter}
            placeholder="Type to search..."
            className="border-1 shadow-none rounded rounded-0 "
            size="md"
          />
          <button
            type="button"
            onClick={() => setSearchText("")}
            className={`btn  rounded rounded-0 text-primary fw-bold py-1 border  border-1 ${searchText.length > 0 ? "" : "d-none"
              }`}
          >
            X
          </button>


        </InputGroup>

      </div>
      <div className="d-none  d-md-flex justify-content-end">
        <button className="btn btn-primary" onClick={() => { setShowAddEditCounty(true) }}>
          Add County
        </button>
      </div>
    </div>

  )



  const getAllCounties = (params) => {
    setIsLoaded(false)
    setup.getAllCounties(params)
      .then(resp => {
        const data = resp?.counties
        setCounties(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })

  }

  useEffect(() => {
    getAllCounties(`search=${searchText}`)
  }, [searchText])

  useEffect(() => {
    getAllCounties()
  }, [])
  return (
    <Card>
      <Card.Header>
        <Card.Title className="text-primary"> Counties</Card.Title>
      </Card.Header>
      <div className="d-flex  d-md-none p-2 px-3 justify-content-end">
        <button className="btn btn-primary" onClick={() => { setShowAddEditCounty(true) }}>
          Add County
        </button>
      </div>
      <TableTemplate
        dataLoaded={isLoaded}
        rows={counties}
        columns={columns}
        subHeaderNav={subHeaderNav}
      />
      <AddEditCounties
        showModal={showAddEditCounty}
        setShowModal={setShowAddEditCounty}
        toEdit={toEdit}
        setToEdit={setToEdit}
        selected={selected}
        setSelected={setSelected}
        fetchData={getAllCounties} />

      <DeleteCounties
        showModal={showdeleteCounty}
        setShowModal={setShowDeleteCounty}
        selected={selected}
        setSelected={setSelected}
        fetchData={getAllCounties}
      />

    </Card>
  )
}

Counties.propTypes = {}

export default Counties