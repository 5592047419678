import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Spinner } from "react-bootstrap"

import toast from "react-hot-toast";
import * as file from "models/files";

const DeleteFile = props => {
  const [isLoading, setIsLoading] = useState(false)

  const closeModal = () => {
    props.setShowModal(false)
    props.setSelected(null)
    props.fetchData()
    setIsLoading(false)
  }

  const deleteFile = () => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;

    setIsLoading(true)
    file.deleteFile(props?.selected?._id, token)
      .then(resp => {
        toast.success(resp.message)
        closeModal()

      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.message)

      })
  }
  return (
    <React.Fragment>
      <Modal show={props.showModal}
        centered>
        <Modal.Header>
          Delete File
        </Modal.Header>
        <Modal.Body>
          This File will be deleted. Click Ok to proceed.

          <div className="d-flex justify-content-between mt-2 mx-2">
            <button
              className="px-2 btn btn-light"
              onClick={() => closeModal()}
            >
              {" "}
              NO
            </button>{" "}
            <button
              className="px-2 btn btn-primary"
              onClick={() => deleteFile()}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Processing...
                </>
              ) : (
                "OK"
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

DeleteFile.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  fetchData: PropTypes.func

}


export default DeleteFile